import React from "react";
import Nav from "./components/Nav";
//pages
import Home from "./pages/Home";
import Work from "./pages/Work";
import Code from "./pages/Code";
import Contact from "./pages/Contact";
//global Style
import GlobalStyle from "./components/GlobalStyles";
//react router
import { Switch, Route } from "react-router-dom";
//animation
import { AnimatePresence } from "framer-motion";

const App = () => {
  return (
    <div className='App'>
      <GlobalStyle />
      <Nav />
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/code' component={Code} />
          <Route path='/work' component={Work} />
          <Route path='/contact' component={Contact} />
        </Switch>
      </AnimatePresence>
    </div>
  );
};

export default App;
