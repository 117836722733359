import React from "react";

//style & animation
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

//tagIcons
import react from "../img/tagIcons/react.svg";
import redux from "../img/tagIcons/redux.svg";
import js from "../img/tagIcons/js.svg";
import mongodb from "../img/tagIcons/mongodb.svg";
import express from "../img/tagIcons/express.svg";
import styledcomponents from "../img/tagIcons/styled.png";
import framermotion from "../img/tagIcons/framermotion.png";
import google from "../img/tagIcons/google.png";
import api from "../img/tagIcons/api.svg";
import sass from "../img/tagIcons/sass.svg";

const Tags = ({ tags }) => {
  const tagIcon = (tagName) => {
    switch (tagName) {
      case "react":
        return react;
      case "redux":
        return redux;
      case "JavaScript":
        return js;
      case "styled-components":
        return styledcomponents;
      case "mongoDB":
        return mongodb;
      case "Express.js":
        return express;
      case "framer motion":
        return framermotion;
      case "REST API":
        return api;
      case "google cloud storage":
        return google;
      case "Sass":
        return sass;
      default:
        break;
    }
  };

  return (
    <TagElements>
      <ul>
        {tags.map((el, i) => (
          <li key={i}>
            <img src={tagIcon(el)} alt='' />
            {el}
          </li>
        ))}
      </ul>
    </TagElements>
  );
};

const TagElements = styled(motion.div)`
  ul {
    margin-bottom: 2rem;
    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      margin: 0.5rem 0;
      img {
        height: 1.5rem;
        max-width: 1.5rem;
        display: inline-block;
        position: relative;
        margin-right: 1rem;
        object-fit: contain;
      }
    }
  }
`;

export default Tags;
