import React from "react";

//style & animation
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Logo, Description, JobsContainer } from "../styles";

//images
import pipes from "../img/pipes.jpg";
import brz from "../img/BRZ2.png";
import baugruppe from "../img/baugruppe.png";
import schachtbau from "../img/schachtbau.png";
import wackerneuson from "../img/wackerneuson.png";
import lohr from "../img/lohr.png";

const Work = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 800 },
    show: { opacity: 1, y: 0, transition: { duration: 2 } },
  };

  return (
    <Wrapper>
      <AnimatePresence>
        <JobsContainer variants={container} initial='hidden' animate='show'>
          <Logo variants={item}>
            <img src={brz} alt='logo brz' />
          </Logo>
          <Description variants={item}>
            <div className='textWrapper'>
              <h3>product owner</h3>
              <p>
                .responsible for agile software development of several products
                <br />
                .with internal and external development teams
                <br />
                .for customers in the construction industry
                <br />
                .modern Workplace, M365 and AZURE <br />
              </p>
            </div>{" "}
          </Description>
          <Logo variants={item}>
            <img src={baugruppe} alt='logo baugruppe' />
          </Logo>
          <Description variants={item}>
            <div className='textWrapper'>
              <h3>business process manager</h3>
              <p>
                .optimization and modeling of business processes
                <br />
                .groupwide with focus on construction companies and central
                services
                <br />
                .projects to increase digitization and automation
                <br />
                .manage software migration projects
              </p>
            </div>
          </Description>
          <Logo variants={item}>
            <img src={schachtbau} alt='logo schachtbau' />
          </Logo>
          <Description variants={item}>
            <div className='textWrapper'>
              <h3>CTO & managing director</h3>
              <p>
                .strategy and market development
                <br />
                .process and organizational optimization
                <br />
                .lawsuits and claim management
                <br />
                .calculation and sales
                <br />
                .project based purchasing and contracting
              </p>
            </div>
          </Description>
          <Logo variants={item}>
            <img src={wackerneuson} alt='logo wachkerneuson' />
          </Logo>
          <Description variants={item}>
            <div className='textWrapper'>
              <h3>strategic purchaser</h3>
              <p>
                .lead buyer for diesel engines and periphery
                <br />
                .international supplier search and qualification
                <br />
                .contract and price negotiation
                <br />
                .traget costing of new development projects
                <br />
                .cost and supplier reduction projects across the group
              </p>
            </div>
          </Description>
          <Logo variants={item}>
            <img src={lohr} alt='logo Lohr' />
          </Logo>
          <Description variants={item}>
            <div className='textWrapper'>
              <h3>project manager</h3>
              <p>
                .engineer and lead plant construction projects <br />
                .drinkwater projects and welded pipework <br />
                .project based purchasing and contracting <br />
                .calculation and sales
              </p>
            </div>
          </Description>
        </JobsContainer>
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  height: 90vh;
  width: 100vw;
  background: url(${pipes}) no-repeat center center fixed;
  background-size: cover;
  overflow-y: auto;
  padding-bottom: 3rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: darkgrey;
  }
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
  }
`;

export default Work;
