export const projects = [
  {
    heading: "loefflerjulia.de",
    description:
      "MERN app to help learning reading in german primary school. Admin section with drag-and-drop picture upload to cloud storage.",
    link: "https://loefflerjulia.de",
    tags: [
      "react",
      "redux",
      "JavaScript",
      "styled-components",
      "mongoDB",
      "Express.js",
      "framer motion",
      "REST API",
      "google cloud storage",
    ],
    images: [
      "https://storage.googleapis.com/loefflermartin_projects/loefflerjulia1.png",
    ],
  },
  {
    heading: "255 clock",
    description: "25+5 clock solution for the freeCodeCamp certifcate.",
    link: "https://clock255-bchawrp2qq-ew.a.run.app",
    tags: [
      "react",
      "redux",
      "JavaScript",
      "styled-components",
      "framer motion",
    ],
    images: [
      "https://storage.googleapis.com/loefflermartin_projects/255clock.png",
    ],
  },
  {
    heading: "ignite",
    description:
      "Info site about games using the rawg API. Part of the Creative React certificate.",
    link: "https://ignite.loefflermartin.de/",
    tags: [
      "react",
      "redux",
      "JavaScript",
      "styled-components",
      "framer motion",
    ],
    images: [
      " https://storage.googleapis.com/loefflermartin_projects/ignite1.png",
    ],
  },
  {
    heading: "capture",
    description:
      "Fictonal portfolio site. Part of the Creative React certificate.",
    link: "https://capture.loefflermartin.de/",
    tags: ["react", "JavaScript", "styled-components", "framer motion"],
    images: [
      "https://storage.googleapis.com/loefflermartin_projects/capture1.png",
    ],
  },
  {
    heading: "musicplayer",
    description:
      "Player frontend for streaming music from web source. Part of the Creative React certificate.",
    link: "https://musicplayer.loefflermartin.de/",
    tags: ["react", "JavaScript", "Sass"],
    images: [
      "https://storage.googleapis.com/loefflermartin_projects/musicplayer1.png",
    ],
  },
  {
    heading: "markdown previewer",
    description:
      "Live previewer for github flavoured markdown. Part of the freeCodeCamp certificate.",
    link: "https://markdownpreview-bchawrp2qq-ew.a.run.app/",
    tags: ["react", "JavaScript", "styled-components", "framer motion"],
    images: [
      "https://storage.googleapis.com/loefflermartin_projects/markdown.png",
    ],
  },
];
