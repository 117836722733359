import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  overflow: hidden;
  
  &::-webkit-scrollbar{
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb{
    background: darkgrey;
  }
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
  }

}
body{
 font-family: 'Rajdhani', sans-serif;
  width: 100%;
  background: rgb(0,0,0);
}

a{
  text-decoration: none;
  color: rgb(100,100,100);
}
img{
  display: block;
}
`;

export default GlobalStyle;
