import React from "react";

//style & animation
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import {} from "../styles";

//images
import workspace from "../img/workspace_small.jpg";
import xing from "../img/xing.png";
import github from "../img/github.png";
import linkedin from "../img/linkedin.png";
import twitter from "../img/twitter.png";
import instagram from "../img/instagram.png";

const Contact = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, x: 300 },
    show: { opacity: 1, x: 0, transition: { duration: 2 } },
  };

  return (
    <Wrapper>
      <AnimatePresence>
        <ContactIcons variants={container} initial='hidden' animate='show'>
          <Logo variants={item}>
            <a href='https://twitter.com/golfomania' target='_blank'>
              <Icon
                src={twitter}
                alt='twitter account'
                whileHover={{
                  x: 15,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
              />
            </a>
          </Logo>

          <Logo variants={item}>
            <a href='https://github.com/golfomania' target='_blank'>
              <Icon
                src={github}
                alt='github account'
                whileHover={{
                  x: 15,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
              />
            </a>
          </Logo>
          <Logo variants={item}>
            <a
              href='https://www.xing.com/profile/Martin_Loeffler74'
              target='_blank'
            >
              <Icon
                src={xing}
                alt='xing account'
                whileHover={{
                  x: 15,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
              />
            </a>
          </Logo>
          <Logo variants={item}>
            <a
              href='https://de.linkedin.com/in/martin-l%C3%B6ffler-46016845'
              target='_blank'
            >
              <Icon
                src={linkedin}
                alt='linkedin account'
                whileHover={{
                  x: 15,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
              />
            </a>
          </Logo>
          <Logo variants={item}>
            <a
              href='https://www.instagram.com/martin.loeffler5/'
              target='_blank'
            >
              <Icon
                src={instagram}
                alt='instagram account'
                whileHover={{
                  x: 15,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
              />
            </a>
          </Logo>
        </ContactIcons>
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  height: 100vh;
  background: url(${workspace}) no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;
`;

const ContactIcons = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const Logo = styled(motion.div)`
  a {
    display: block;
  }
`;

const Icon = styled(motion.img)`
  margin: 1rem;
  height: 3rem;
  @media (max-width: 600px) {
    height: 4rem;
  }
`;

export default Contact;
