import React, { useState } from "react";

//components
import Tags from "../components/Tags";

//style & animation
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
//images
import { projects } from "../projects";
import right from "../img/right.svg";
import left from "../img/left.svg";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Code = () => {
  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const projectsIndex = wrap(0, projects.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <Wrapper>
      <h1>code</h1>
      <AnimatePresence initial={false} custom={direction}>
        <Description>
          <h2>{projects[projectsIndex].heading}</h2>
          <p>{projects[projectsIndex].description}</p>
          <Tags tags={projects[projectsIndex].tags} />
          <a href={projects[projectsIndex].link} target='_blank'>
            view live
          </a>
        </Description>
        <motion.img
          key={page}
          src={projects[projectsIndex].images[0]}
          custom={direction}
          variants={variants}
          initial='enter'
          animate='center'
          exit='exit'
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag='x'
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      <div className='next' onClick={() => paginate(1)}>
        <img src={right} alt='right arrow' className='arrow' />
      </div>
      <div className='prev' onClick={() => paginate(-1)}>
        <img src={left} alt='left arrow' className='arrow' />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  background: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;

  h1,
  p {
    color: white;
  }
  .arrow{
    height: 2.5rem;
    width: 2.5rem;
  }
  h1 {
    font-size: 50rem;
    @media (max-width: 1400px) {
      font-size: 45rem;
    }
    @media (max-width: 1300px) {
      font-size: 40rem;
    }
    @media (max-width: 1100px) {
      font-size: 35rem;
    }
    @media (max-width: 1000px) {
      font-size: 30rem;
    }
    @media (max-width: 850px) {
      font-size: 25rem;
    }
    @media (max-width: 700px) {
      opacity:0;
    }
    @media (max-width: 600px) {
      font-size: 15rem;
    }
  }
  }

  .next,
  .prev {
    top: calc(50% - 20px);
    position: absolute;
    background: rgba(194, 194, 194, 0);
    box-shadow: 0px 0px 60px 13px #ffffff;
    //border-radius: 30px;
    width: 1px;
    height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
    color: rgb(0, 0, 0);
  }

  .next {
    right: 25px;
  }

  .prev {
    left: 25px;
    //transform: scale(-1);
  }

  img {
    position: absolute;
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    //max-width: 100vw;
    max-height: 100vh;
    object-fit: cover;
    
  }
  


  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  `;

const Description = styled(motion.div)`
  position: absolute;
  right: 70px;
  z-index: 3;
  width: 15rem;
  height: fit-content;
  padding: 1rem;
  background: rgba(128, 128, 128, 0.9);
  @media (max-width: 600px) {
    right: 50%;
    left: 50%;
    bottom: 10vh;
    transform: translate(-50%, -0%);
  }
  h1,
  p {
    color: black;
  }
  p {
    margin-bottom: 0.75rem;
  }
  a {
    position: relative;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background: rgba(0, 0, 0, 0.7);
  }
`;

export default Code;
