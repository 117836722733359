//style & animation
import styled from "styled-components";
import { motion } from "framer-motion";

//////////////////////////////////////////////
//Home
//////////////////////////////////////////////

export const HomeDiv = styled(motion.div)`
  background: transparent linear-gradient(359deg, #083966 0%, rgb(0, 0, 0) 100%)
    0% 0% no-repeat padding-box;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 910px) {
    overflow-y: auto;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "nametag"
      "textbox1"
      "textbox2"
      "textbox3"
      "textbox4"
      "footer";
  }
`;

export const Banner = styled(motion.div)`
  width: 100%;
  height: auto;
  position: absolute;
  top: 15vh;

  img {
    position: relative;
    width: 100%;
    height: 38vh;
    object-fit: cover;
    overflow: hidden;
    opacity: 0.33;
    @media (max-width: 910px) {
      height: 17rem;
    }
  }
`;

export const TextBox1 = styled(motion.div)`
  position: relative;
  overflow: none;
  top: 20vh;
  left: 10vw;
  @media (max-width: 910px) {
    grid-area: textbox1;
    top: 0vh;
    left: 0;
    margin: 2rem 0;
  }

  width: fit-content;
  background: rgba(255, 255, 255, 0.06);

  display: flex;
  flex-direction: column;

  padding: 0.5rem 1rem;

  h3 {
    display: block;
    color: #c4c4c4;
    font-size: 1.4rem;
  }
  p {
    display: block;
    color: #c4c4c4;
    font-size: 1.25rem;
  }
  .leftCorner1 {
    position: absolute;
    top: 0;
    left: -0.2rem;
    width: 0.2rem;
    height: 1.4rem;
    background: white;
  }
  .leftCorner2 {
    position: absolute;
    top: -0.2rem;
    left: -0.2rem;
    height: 0.2rem;
    width: 1.6rem;
    background: white;
  }
  .rightCorner1 {
    position: absolute;
    bottom: 0;
    right: -0.2rem;
    width: 0.2rem;
    height: 1.4rem;
    background: white;
  }
  .rightCorner2 {
    position: absolute;
    bottom: -0.2rem;
    right: -0.2rem;
    height: 0.2rem;
    width: 1.6rem;
    background: white;
  }
`;

export const TextBox2 = styled(motion.div)`
  position: relative;
  overflow: none;
  top: 25vh;
  left: 75vw;
  @media (max-width: 1300px) {
    left: 60vw;
  }
  @media (max-width: 910px) {
    grid-area: textbox2;
    top: 0vh;
    left: 0;
    margin: 2rem 0;
  }

  width: fit-content;
  background: rgba(255, 255, 255, 0.06);

  display: flex;
  flex-direction: column;

  padding: 0.5rem 1rem;

  h3 {
    display: block;
    color: #c4c4c4;
    font-size: 1.4rem;
  }
  p {
    display: block;
    color: #c4c4c4;
    font-size: 1.25rem;
  }
  .leftCorner1 {
    position: absolute;
    top: 0;
    left: -0.2rem;
    width: 0.2rem;
    height: 1.4rem;
    background: white;
  }
  .leftCorner2 {
    position: absolute;
    top: -0.2rem;
    left: -0.2rem;
    height: 0.2rem;
    width: 1.6rem;
    background: white;
  }
  .rightCorner1 {
    position: absolute;
    bottom: 0;
    right: -0.2rem;
    width: 0.2rem;
    height: 1.4rem;
    background: white;
  }
  .rightCorner2 {
    position: absolute;
    bottom: -0.2rem;
    right: -0.2rem;
    height: 0.2rem;
    width: 1.6rem;
    background: white;
  }
`;

export const TextBox3 = styled(motion.div)`
  position: relative;
  overflow: none;
  top: 60vh;
  left: 17vw;
  @media (max-width: 910px) {
    grid-area: textbox3;
    top: 0vh;
    margin: 2rem 0;

    left: 0;
    width: 90%;
  }
  width: fit-content;
  background: rgba(255, 255, 255, 0.06);

  display: flex;
  flex-direction: column;

  padding: 0.5rem 1rem;

  h3 {
    display: block;
    color: #c4c4c4;
    font-size: 1.4rem;
  }
  p {
    display: block;
    color: #c4c4c4;
    font-size: 1.25rem;
  }
  .leftCorner1 {
    position: absolute;
    top: 0;
    left: -0.2rem;
    width: 0.2rem;
    height: 1.4rem;
    background: white;
  }
  .leftCorner2 {
    position: absolute;
    top: -0.2rem;
    left: -0.2rem;
    height: 0.2rem;
    width: 1.6rem;
    background: white;
  }
  .rightCorner1 {
    position: absolute;
    bottom: 0;
    right: -0.2rem;
    width: 0.2rem;
    height: 1.4rem;
    background: white;
  }
  .rightCorner2 {
    position: absolute;
    bottom: -0.2rem;
    right: -0.2rem;
    height: 0.2rem;
    width: 1.6rem;
    background: white;
  }
`;

export const TextBox4 = styled(motion.div)`
  position: relative;
  overflow: none;
  top: 41vh;
  left: 63vw;

  @media (max-width: 1100px) {
    top: 28vh;
  }
  @media (max-width: 910px) {
    grid-area: textbox4;
    top: 0vh;
    margin: 2rem 0;
    left: 0;
  }
  width: fit-content;
  background: rgba(255, 255, 255, 0.06);

  display: flex;
  flex-direction: column;

  padding: 0.5rem 1rem;
  h3 {
    display: block;
    color: #c4c4c4;
    font-size: 1.4rem;
  }
  p {
    display: block;
    color: #c4c4c4;
    font-size: 1.25rem;
  }
  .leftCorner1 {
    position: absolute;
    top: 0;
    left: -0.2rem;
    width: 0.2rem;
    height: 1.4rem;
    background: white;
  }
  .leftCorner2 {
    position: absolute;
    top: -0.2rem;
    left: -0.2rem;
    height: 0.2rem;
    width: 1.6rem;
    background: white;
  }
  .rightCorner1 {
    position: absolute;
    bottom: 0;
    right: -0.2rem;
    width: 0.2rem;
    height: 1.4rem;
    background: white;
  }
  .rightCorner2 {
    position: absolute;
    bottom: -0.2rem;
    right: -0.2rem;
    height: 0.2rem;
    width: 1.6rem;
    background: white;
  }
`;

export const NameTag = styled(motion.div)`
  position: absolute;
  top: 45vh;
  @media (max-width: 910px) {
    grid-area: nametag;
    position: relative;
    top: 20rem;
    height: 30rem;
  }
  text-align: center;
  width: 100%;
  h1 {
    color: rgba(255, 255, 255, 0.774);
    font-size: 10rem;
    letter-spacing: 1.5rem;
    font-family: "Nothing You Could Do", cursive;
    @media (max-width: 1600px) {
      font-size: 8rem;
    }
    @media (max-width: 1350px) {
      font-size: 6rem;
    }
    @media (max-width: 1100px) {
      font-size: 5rem;
    }
    @media (max-width: 910px) {
      font-size: 3rem;
    }
  }
`;

//////////////////////////////////////////////
//Work
//////////////////////////////////////////////
export const JobsContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled(motion.div)`
  width: 50rem;
  @media (max-width: 860px) {
    width: 30rem;
  }
  @media (max-width: 600px) {
    width: 85%;
  }
  background: rgba(0, 0, 0, 0.85);
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    padding: 1.5rem;
    height: 7rem;
    @media (max-width: 600px) {
      max-height: 5rem;
      max-width: 100%;
    }
  }
`;

export const Description = styled(motion.div)`
  width: 50rem;
  margin-top: 0.5rem;
  background: rgba(0, 0, 0, 0.85);

  @media (max-width: 860px) {
    width: 30rem;
  }
  @media (max-width: 600px) {
    width: 85%;
  }

  .textWrapper {
    width: 100%;
    display: flex;
    padding: 2rem;
    justify-content: center;
    @media (max-width: 860px) {
      flex-direction: column;
    }

    h3 {
      font-size: 2rem;
      width: 50%;
      color: white;
      @media (max-width: 860px) {
        width: 100%;
      }
      @media (max-width: 600px) {
        font-size: 1.5rem;
      }
    }
    p {
      font-size: 1.2rem;
      width: 50%;
      color: white;
      line-height: 2;
      @media (max-width: 860px) {
        margin-top: 2rem;
        width: 100%;
      }
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }
  }
`;
