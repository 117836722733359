import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
//images
import logo from "../img/ML.png";

const Nav = () => {
  //current location for current animation
  const { pathname } = useLocation();

  return (
    <SyledNav>
      <Link className='logo' to='/'>
        <img src={logo} alt='logo' />
      </Link>
      <ul>
        <li>
          <Line
            transition={{ duration: 0.75, ease: "easeOut" }}
            initial={{ width: "0%" }}
            animate={{
              width: pathname === "/" ? "100%" : "0%",
              opacity: pathname === "/" ? "1" : "0",
            }}
          />
          <Link to='/'>
            <Text
              whileHover={{
                y: -5,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              about
            </Text>
          </Link>
        </li>
        <li>
          <Line
            transition={{ duration: 0.75, ease: "easeOut" }}
            initial={{ width: "0%" }}
            animate={{
              width: pathname === "/code" ? "100%" : "0%",
              opacity: pathname === "/code" ? "1" : "0",
            }}
          />
          <Link to='/code'>
            <Text
              whileHover={{
                y: -5,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              code
            </Text>
          </Link>
        </li>
        <li>
          <Line
            transition={{ duration: 0.75, ease: "easeOut" }}
            initial={{ width: "0%" }}
            animate={{
              width: pathname === "/work" ? "100%" : "0%",
              opacity: pathname === "/work" ? "1" : "0",
            }}
          />
          <Link to='/work'>
            <Text
              whileHover={{
                y: -5,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              work
            </Text>
          </Link>
        </li>
        <li>
          <Line
            transition={{ duration: 0.75, ease: "easeOut" }}
            initial={{ width: "0%" }}
            animate={{
              width: pathname === "/contact" ? "100%" : "0%",
              opacity: pathname === "/contact" ? "1" : "0",
            }}
          />
          <Link to='/contact'>
            <Text
              whileHover={{
                y: -5,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              contact
            </Text>
          </Link>
        </li>
      </ul>
    </SyledNav>
  );
};

const SyledNav = styled(motion.nav)`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  min-height: 5vh;
  background: transparent;
  ul {
    display: flex;
    list-style-type: none;
    margin-left: 1rem;
    li {
      margin: 1.5rem 0.5rem;
      position: relative;

      a {
        color: rgb(180, 180, 180);
        font-size: 1.2rem;
      }
    }
  }
  .logo {
    margin-left: 2rem;
    cursor: pointer;

    img {
      width: 3rem;
    }
  }
`;

const Line = styled(motion.div)`
  height: 0.2rem;
  width: 0%;
  background: rgb(180, 180, 180);
  position: absolute;
  top: -0.3rem;
`;

const Text = styled(motion.p)``;

export default Nav;
