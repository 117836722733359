import React from "react";
//style & animation
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import {
  HomeDiv,
  Banner,
  TextBox1,
  TextBox2,
  TextBox3,
  TextBox4,
  NameTag,
} from "../styles";
//images
import myself from "../img/myself_stripe.png";

const Home = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const item = {
    hidden: { opacity: 1, scale: 0.3 },
    show: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  return (
    <AnimatePresence>
      <HomeDiv variants={container} initial='hidden' animate='show'>
        <Banner>
          <img src={myself} alt='banner' />
        </Banner>
        <TextBox1 variants={item}>
          <div className='leftCorner1'></div>
          <div className='leftCorner2'></div>
          <h3>born</h3>
          <p>1981 in Ulm, Germany</p>
          <div className='rightCorner1'></div>
          <div className='rightCorner2'></div>
        </TextBox1>
        <TextBox2 variants={item}>
          <div className='leftCorner1'></div>
          <div className='leftCorner2'></div>
          <h3>graduation</h3>
          <p>Diplom-Wirtschafts-Ingenieur (FH)</p>
          <div className='rightCorner1'></div>
          <div className='rightCorner2'></div>
        </TextBox2>
        <TextBox3 variants={item}>
          <div className='leftCorner1'></div>
          <div className='leftCorner2'></div>
          <h3>education</h3>
          <p>
            industrial engineering and management <br />
            at Hochschule Ravensburg-Weingarten, Germany <br />
            business studies <br />
            at LYIT Letterkenny institute of technology, Ireland
          </p>
          <div className='rightCorner1'></div>
          <div className='rightCorner2'></div>
        </TextBox3>
        <TextBox4 variants={item}>
          <div className='leftCorner1'></div>
          <div className='leftCorner2'></div>
          <h3>personally</h3>
          <p>
            married, one daughter <br />
            living near Ulm, Germany
          </p>
          <div className='rightCorner1'></div>
          <div className='rightCorner2'></div>
        </TextBox4>
        <NameTag>
          <h1>Martin Löffler</h1>
        </NameTag>
        <Footer>
          <p>
            <a href='https://loefflermartin.de' target='_blank'>
              martin loeffler
            </a>{" "}
            / 2021 /{" "}
            <a href='https://impressum.loefflermartin.de' target='_blank'>
              impressum
            </a>
          </p>
        </Footer>
      </HomeDiv>
    </AnimatePresence>
  );
};

const Footer = styled(motion.div)`
  position: absolute;
  bottom: 0;
  height: 3rem;
  width: 100%;
  color: rgba(196, 196, 196, 0.5);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: rgba(196, 196, 196, 0.5);
  }
  @media (max-width: 910px) {
    position: relative;
    grid-area: footer;
  }
`;

export default Home;
